.smehome {
  background-color: #f2f2f2;
  position: absolute;
  padding-bottom: 100px;
}
.smebody {
  width: 100%;
  margin: 30px 0px;
}
.smemallVirtual {
  width: 100%;
}
.smeNavi {
  background-color: white;
  position: fixed;
  width: 100%;
  bottom: 0px;
  max-height: 70px;
  height: auto;
}

@media only screen and (min-width: 428px) {
  .smehome {
    width: 50%;
    margin-left: 25%;
  }
  .smehome-header {
    width: 50%;
  }
  .smebody {
    width: 50%;
    margin-left: 25%;
  }
  .smemallVirtual {
    width: 50%;
    margin-left: 25%;
  }
  .smeNavi {
    width: 50%;
    margin-left: 25%;
  }
}

.body {
  padding-bottom: 2rem;
  height: 100%;
  width: 100%;
  position: absolute;
}
.bodyZonepreview {
  width: 100%;
}
.bodyNavi {
  background-color: white;
  position: fixed;
  bottom: 0;
  max-height: 65px;
  height: auto;
  overflow-y: hidden;
}
.mapShow {
  width: 100%;
  height: 86vh;
  position: absolute;
}
.bottomZonePreview {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.drawer {
  width: 100%;
}
.loginBG {
  background-image: url("assets/img/loginBG.png");
  height: 272px;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media only screen and (min-width: 428px) {
  .body {
    width: 50%;
    margin-left: 25%;
  }
  .bodyZonepreview {
    width: 50%;
    margin-left: 25%;
  }
  .bodyNavi {
    width: 50%;
  }
  .mapShow {
    width: 50%;
  }
  .bottomZonePreview {
    width: 50%;
    margin-left: 25%;
  }
}
