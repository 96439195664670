.CalendarDay__selected_span {
    background: #82e0aa;
    color: white;
    border: 1px solid lightred;
}
.CalendarDay__selected {
    background: #55BBB4;
    color: white;
}
.CalendarDay__selected:hover {
    background: #55BBB4;
    color: white;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: brown;
}
